import React from 'react';
import { Link } from 'react-scroll'; // Import Link from react-scroll
import '../style/Office.css';

const Office = () => {
  return (
    <section className="office-section">
      <div className="container">
        <div className="text-content">
          <h1>OUR OFFICE</h1>
          <p>
            At MY, we pride ourselves on being the premier real estate experts in Jerusalem’s most prestigious neighborhoods. Our team of fifteen seasoned agents brings a wealth of experience, offering personalized services in English, Hebrew, and French. We cater to both local and international clients, ensuring that every step of your real estate journey is handled with the utmost care and professionalism. Whether you're buying, selling, or investing, our deep knowledge of the Jerusalem market and commitment to excellence guarantees a smooth and successful experience tailored to your unique needs.
          </p>
          <Link 
            to="services" // Link to the Services section
            smooth={true} 
            duration={1000} 
            className="read-more-btn"
          >
            Read More ➞
          </Link>
        </div>
        <div className="image-content">
          <img src="https://www.mobili.co.il/wp-content/uploads/2022/01/074A2104.jpg" alt="JHomes Office" />
        </div>
      </div>
    </section>
  );
};

export default Office;
