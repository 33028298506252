import React, { useState } from "react";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/addHouse.css';

const AddHouse = ({ addHouse }) => {
  const [house, setHouse] = useState({
    id: "",
    title: "",
    address: "",
    price: "",
    main_image: [],
    rooms: "",
    bedrooms: "",
    sqm: "",
    floor: "",
    description: "",
    latitude: '',
    longitude: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'main_image') {
      setHouse({
        ...house,
        main_image: value.split(',').map(img => img.trim()),
      });
    } else {
      setHouse({
        ...house,
        [name]: value,
      });
    }
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const readers = files.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(readers).then((images) => {
      setHouse((prevHouse) => ({
        ...prevHouse,
        main_image: [...prevHouse.main_image, ...images],
      }));
      // Reset the file input value to allow re-uploading the same files
      e.target.value = null;
    });
  };

  const handleImageChange = (index, value) => {
    const newImages = [...house.main_image];
    newImages[index] = value;
    setHouse({
      ...house,
      main_image: newImages,
    });
  };

  const handleAddImage = () => {
    setHouse({
      ...house,
      main_image: [...house.main_image, ""],
    });
  };

  const handleRemoveImage = (index) => {
    const newImages = house.main_image.filter((_, i) => i !== index);
    setHouse({
      ...house,
      main_image: newImages,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to add this house?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add it!'
      }).then((result) => {
        if (result.isConfirmed) {
          const newHouse = {
            ...house,
            id: Date.now(),
          };
          addHouse(newHouse);
          Swal.fire({
            title: 'Success!',
            text: 'House added successfully.',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            willClose: () => {
              setHouse({
                id: "",
                title: "",
                address: "",
                price: "",
                main_image: [],
                rooms: "",
                bedrooms: "",
                sqm: "",
                floor: "",
                description: "",
                latitude: '',
                longitude: '',
              });
            }
          });
        }
      });
    } else {
      toast.error("Failed to add house. Please fill in all required fields.");
    }
  };

  const validateForm = () => {
    if (!house.title || !house.address || !house.price || house.main_image.length === 0 || !house.rooms || !house.bedrooms || !house.sqm || !house.floor || !house.description || !house.latitude || !house.longitude) {
      toast.info("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  return (
    <div className="custom-add-house-container">
      <div className="custom-add-house-card">
        <h1>Add House</h1>
        <form onSubmit={handleSubmit} className="custom-add-house-form">
          <div className="custom-form-group">
            <label>Title</label>
            <input type="text" name="title" value={house.title} onChange={handleChange} required />
          </div>
          <div className="custom-form-group">
            <label>Address</label>
            <input type="text" name="address" value={house.address} onChange={handleChange} required />
          </div>
          <div className="custom-form-group">
            <label>Price</label>
            <input type="text" name="price" value={house.price} onChange={handleChange} required />
          </div>
          <div className="custom-form-group">
            <label>Main Images</label>
            {house.main_image.map((image, index) => (
              <div key={index} className="custom-image-input">
                <input
                  type="text"
                  value={image}
                  onChange={(e) => handleImageChange(index, e.target.value)}
                  placeholder={`Image URL ${index + 1}`}
                />
                <button type="button" onClick={() => handleRemoveImage(index)} className="remove-image-btn">Remove</button>
              </div>
            ))}
            <label htmlFor="file-upload" className="custom-upload-label">
              Choose Files
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="custom-image-upload"
              multiple
            />
            <button type="button" onClick={handleAddImage} className="custom-add-image-btn">+ Add Image</button>
          </div>
          <div className="custom-form-group">
            <label>Rooms</label>
            <input type="text" name="rooms" value={house.rooms} onChange={handleChange} required />
          </div>
          <div className="custom-form-group">
            <label>Bedrooms</label>
            <input type="text" name="bedrooms" value={house.bedrooms} onChange={handleChange} required />
          </div>
          <div className="custom-form-group">
            <label>Square Meters</label>
            <input type="text" name="sqm" value={house.sqm} onChange={handleChange} required />
          </div>
          <div className="custom-form-group">
            <label>Floor</label>
            <input type="text" name="floor" value={house.floor} onChange={handleChange} required />
          </div>
          <div className="custom-form-group">
            <label>Description</label>
            <textarea name="description" value={house.description} onChange={handleChange} required />
          </div>
          <div className="custom-form-group">
            <label>Latitude</label>
            <input type="text" name="latitude" value={house.latitude} onChange={handleChange} required />
          </div>
          <div className="custom-form-group">
            <label>Longitude</label>
            <input type="text" name="longitude" value={house.longitude} onChange={handleChange} required />
          </div>
          <button type="submit" className="custom-button">Add House</button>
        </form>
      </div>
    </div>
  );
};

export default AddHouse;
