import React, { useState } from 'react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import '../style/Contact.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: '',
    });

    const [emailSent, setEmailSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
  
      emailjs.send(
          'service_god9szb', // Replace with your EmailJS Service ID
          'template_olzbrar', // Replace with your EmailJS Template ID
          formData,
          'eeO_XMmDeEVeDniM-' // Replace with your EmailJS User ID
      ).then(
          (result) => {
              console.log(result.text);
              setEmailSent(true);
              setErrorMessage('');
              setFormData({ // Clear the form fields after successful submission
                  name: '',
                  phone: '',
                  email: '',
                  message: '',
              });
          },
          (error) => {
              console.log(error.text);
              setEmailSent(false);
              setErrorMessage('Failed to send the message. Please try again later.');
          }
      );
  };
  

    return (
        <section className="contact-section">
            <div className="contact-container">
                <div className="contact-image">
                    <img src="https://miro.medium.com/v2/resize:fit:1400/1*JU-W064oN0wawQCBrGj34g@2x.jpeg" alt="Contact" />
                </div>
                <div className="contact-form">
                    <h2>SEND US A MESSAGE</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="phone"
                                    placeholder="Phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group half-width">
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                        </div>
                        <div className="form-footer">
                            <button type="submit" className="send-message-button">SEND MESSAGE ➞</button>
                        </div>
                        {emailSent && <p>Your message has been sent!</p>}
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        <div className="form-footer">
                            <div className="credit-section">
                                <div className="credit-text">
                                    <span>Developed by Yehuda Collins</span>
                                </div>
                                <div className="small-social-icons">
                                    <a href="https://wa.me/972534538331?text=%D7%94%D7%99%D7%99%2C%20%D7%94%D7%92%D7%A2%D7%AA%D7%99%20%D7%A2%D7%9C%D7%99%D7%9A%20%D7%93%D7%A8%D7%9A%20%D7%94%D7%90%D7%AA%D7%A8%20Brainiac%20%D7%A9%D7%A4%D7%99%D7%AA%D7%97%D7%AA" target="_blank" rel="noopener noreferrer">
                                        <FaWhatsapp />
                                    </a>
                                    <a href="https://www.instagram.com/yehuda_collins?igsh=MXQ2MDNrajBvd2hhbw==" target="_blank" rel="noopener noreferrer">
                                        <FaInstagram />
                                    </a>
                                </div>
                            </div>
                            <div className="social-icons">
                                <a href="https://wa.me/972522352225?text=היי+התעניינתי+בדירות+שיש+לכם+להציע" target="_blank" rel="noopener noreferrer">
                                    <FaWhatsapp />
                                </a>
                                <a href="https://www.instagram.com/yehonatanmargalit/" target="_blank" rel="noopener noreferrer">
                                    <FaInstagram />
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
