import React from 'react';
import '../style/Home.css';
import { FaChevronDown } from 'react-icons/fa';

const Home = () => {
  const backgroundImageStyle = {
    backgroundImage: 'url("https://www.zohar-arc.co.il/webp/646c9827f24211684838439_1_file.webp")',
  };

  return (
    <div id="home" className="home" style={backgroundImageStyle}>
      <div className="overlay">
        <div className="content">
          <div className="logo">
            <img src="./pictures/logo.ico" alt="Jerusalem Fine Realty" />
          </div>
          <h1>Welcome To</h1>
          <h2>YM</h2>
          <h3>Yehonatan Kings Jerusalem</h3>
          <p>27 Metzare Tiran Street</p>
          <div className="scroll-down">
            <FaChevronDown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
