import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../style/AdminPanel.css'; // Ensure the correct path for the CSS file

function AdminModal({ onClose, onLogin }) {
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        if (password === '2141') {
            onLogin();  // Set authentication status in the parent
            navigate('/admin');  // Redirect to the admin page
        } else {
            alert('Incorrect password');
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Admin Login</h2>
                <form onSubmit={handleLogin}>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter Admin Password"
                        required
                    />
                    <div className="button-group">
                        <button type="submit">Login</button>
                        <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default AdminModal;
