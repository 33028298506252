import { useLoadScript } from '@react-google-maps/api';

const libraries = ['places'];

const MapLoader = ({ children }) => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAtMNSPG4kumxoWSX5PJl8EMWjBy5Oo9o4',
        language: 'iw',
        libraries: libraries,
    });

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading maps...</div>;

    return children;
};

export default MapLoader;
