import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { GoogleMap, Marker } from '@react-google-maps/api';
import '../style/HouseDetail.css';
import { FaBed, FaBath, FaRulerCombined, FaLayerGroup } from 'react-icons/fa';
import MapLoader from './MapLoader'; // ייבוא MapLoader

const HouseDetail = ({ houses }) => {
    const { id } = useParams();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hoveredImage, setHoveredImage] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [id]);

    if (!houses || houses.length === 0) {
        return <p>No house data available</p>;
    }

    const house = houses.find(h => h.firebaseId === id);

    if (!house) return <p>House not found</p>;

    const handleNext = () => {
        if (currentIndex < house.main_image.length - 5) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    return (
        <div className="house-detail-wrapper">
            <div className="house-detail-primary-image">
                <img src={hoveredImage || house.main_image[0]} alt={house.title} />
            </div>
            <div className="house-detail-secondary-images-wrapper">
                {house.main_image.length > 5 && (
                    <>
                        <button className="house-detail-carousel-button left" onClick={handlePrevious}>
                            &#8249;
                        </button>
                        <button className="house-detail-carousel-button right" onClick={handleNext}>
                            &#8250;
                        </button>
                    </>
                )}
                <div
                    className="house-detail-secondary-images"
                    style={{ transform: `translateX(-${currentIndex * 120}px)` }}
                >
                    {house.main_image.slice(1).map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`${house.title} - ${index + 1}`}
                            onMouseEnter={() => setHoveredImage(image)}
                            onMouseLeave={() => setHoveredImage(null)}
                        />
                    ))}
                </div>
            </div>
            <div className="house-detail-content">
                <h1 className="house-detail-title">{house.title}</h1>
                <p className="house-detail-address">{house.address}</p>
                <h2 className="house-detail-subtitle">Property Details</h2>
                <div className="house-detail-grid">
                    <div className="house-detail-item">
                        <i className="fa"><FaBath /></i>
                        <span>{house.rooms}</span>
                        <label>Rooms</label>
                    </div>
                    <div className="house-detail-item">
                        <i className="fa"><FaBed /></i>
                        <span>{house.bedrooms}</span>
                        <label>Bedrooms</label>
                    </div>
                    <div className="house-detail-item">
                        <i className="fa"><FaRulerCombined /></i>
                        <span>{house.sqm}</span>
                        <label>Square Meters</label>
                    </div>
                    <div className="house-detail-item">
                        <i className="fa"><FaLayerGroup /></i>
                        <span>{house.floor}</span>
                        <label>Floor</label>
                    </div>
                </div>
                <p className="house-detail-description">{house.description}</p>
                <div className="house-detail-location-map">
                    <h2>Location</h2>
                    <MapLoader>
                        <GoogleMap
                            mapContainerClassName="house-map-container"
                            center={{
                                lat: parseFloat(house.latitude),
                                lng: parseFloat(house.longitude),
                            }}
                            zoom={15}
                            options={{
                                disableDefaultUI: true,
                                zoomControl: false,
                                mapTypeControl: false,
                                streetViewControl: false,
                                fullscreenControl: false,
                            }}
                        >
                            <Marker
                                position={{
                                    lat: parseFloat(house.latitude),
                                    lng: parseFloat(house.longitude),
                                }}
                            />
                        </GoogleMap>
                    </MapLoader>
                </div>
            </div>
        </div>
    );
};

export default HouseDetail;
