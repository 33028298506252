import React from 'react';
import ReactDOM from 'react-dom/client';
import './style/index.css';
import App from './App';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// document.querySelectorAll('*').forEach(function(element) {
//   // Check if the element has a class
//   if (element.className) {
//       const classLabel = document.createElement('div');
//       classLabel.style.position = 'absolute';
//       classLabel.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
//       classLabel.style.color = 'white';
//       classLabel.style.fontSize = '10px';
//       classLabel.style.padding = '2px';
//       classLabel.style.zIndex = '9999';
//       classLabel.style.pointerEvents = 'none'; // Prevent the label from blocking interactions
//       classLabel.innerText = element.className;
//       element.style.position = 'relative';
//       element.insertBefore(classLabel, element.firstChild);
//   }
// });
