// import React from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { Link } from 'react-scroll';
// import '../style/Navbar.css';

// function Navbar({ onAdminClick, isAdminAuthenticated }) {
//   const navigate = useNavigate();

//   const handleNavigation = (sectionId) => {
//     navigate('/');
//     setTimeout(() => {
//         const section = document.getElementById(sectionId);
//         if (section) {
//             const yOffset = -150; // Adjust this value to match your navbar height
//             const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
//             window.scrollTo({ top: y, behavior: 'smooth' });
//         }
//     }, 0);
// };


//   return (
//     <nav className="custom-navbar">
//       <div className="custom-logo-container">
//             <img 
//         src="/pictures/logos.png" 
//         alt="JHomes Logo" 
//         className="custom-logo" 
//         onClick={onAdminClick} 
//       />

//       </div>
//       <div className="custom-nav-links-container">
//         <div className="custom-nav-links">
//           <NavLink 
//             to="/" 
//             className="custom-nav-link" 
//             onClick={() => handleNavigation('home')}
//           >
//             Home
//           </NavLink>
//           <NavLink 
//             to="/" 
//             className="custom-nav-link" 
//             onClick={() => handleNavigation('about-us')}
//           >
//             About Us
//           </NavLink>
//           <NavLink 
//             to="/" 
//             className="custom-nav-link" 
//             onClick={() => handleNavigation('properties')}
//           >
//             Properties
//           </NavLink>
//           <NavLink 
//             to="/" 
//             className="custom-nav-link" 
//             onClick={() => handleNavigation('map')}
//           >
//             map
//           </NavLink>
//           <NavLink 
//             to="/" 
//             className="custom-nav-link" 
//             onClick={() => handleNavigation('contact')}
//           >
//             Contact Us
//           </NavLink>
//           {isAdminAuthenticated && (
//             <NavLink to="/admin" className="custom-nav-link">Admin</NavLink>
//           )}
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;


import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import '../style/Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import hamburger and close icons

function Navbar({ onAdminClick, isAdminAuthenticated }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleNavigation = (sectionId) => {
    navigate('/');
    setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
            const yOffset = -150; // Adjust this value to match your navbar height
            const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    }, 0);
    setIsMenuOpen(false); // Close the menu when navigating
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="custom-navbar">
      <div className="custom-logo-container">
        <img 
          src="/pictures/logos.png" 
          alt="JHomes Logo" 
          className="custom-logo" 
          onClick={onAdminClick} 
        />
      </div>

      <div className={`custom-nav-links-container ${isMenuOpen ? 'open' : ''}`}>
        <div className="custom-nav-links">
          <NavLink 
            to="/" 
            className="custom-nav-link" 
            onClick={() => handleNavigation('home')}
          >
            Home
          </NavLink>
          <NavLink 
            to="/" 
            className="custom-nav-link" 
            onClick={() => handleNavigation('about-us')}
          >
            About Us
          </NavLink>
          <NavLink 
            to="/" 
            className="custom-nav-link" 
            onClick={() => handleNavigation('properties')}
          >
            Properties
          </NavLink>
          <NavLink 
            to="/" 
            className="custom-nav-link" 
            onClick={() => handleNavigation('map')}
          >
            map
          </NavLink>
          <NavLink 
            to="/" 
            className="custom-nav-link" 
            onClick={() => handleNavigation('contact')}
          >
            Contact Us
          </NavLink>
          {isAdminAuthenticated && (
            <NavLink to="/admin" className="custom-nav-link">Admin</NavLink>
          )}
        </div>
      </div>

      <div className="custom-hamburger" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
}

export default Navbar;

