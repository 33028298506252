import React from 'react';
import '../style/Services.css';

function Services() {
  return (
    <div className="services-container">
      <div className="services-image">
        <img src="https://www.mycism.com/wp-content/uploads/2023/02/familyday-2023.jpg" alt="Happy family" />
      </div>
      <div className="services-content">
        <h2 className="services-title">What We Provide</h2>
        <div className="services-grid">
          <div className="service-item">
            <div className="service-number">1</div>
            <div className="service-text">
              <h3>Real Estate Consultation</h3>
              <p>We offer expert consultation services to help you find the perfect home or investment property.</p>
            </div>
          </div>
          <div className="service-item">
            <div className="service-number">2</div>
            <div className="service-text">
              <h3>Property Management</h3>
              <p>Our team handles all aspects of property management, ensuring your property is well-maintained and profitable.</p>
            </div>
          </div>
          <div className="service-item">
            <div className="service-number">3</div>
            <div className="service-text">
              <h3>Legal Assistance</h3>
              <p>We provide legal support throughout the real estate transaction process to protect your interests.</p>
            </div>
          </div>
          <div className="service-item">
            <div className="service-number">4</div>
            <div className="service-text">
              <h3>Financing Options</h3>
              <p>We connect you with the best financing options available to help you secure your dream property.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
