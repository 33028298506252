import React, { useState } from "react";
import { toast } from 'react-toastify';
import '../../style/manageHouses.css';
import Swal from 'sweetalert2';

const ManageHouses = ({ houses, updateHouse, deleteHouse }) => {
  const [editingHouse, setEditingHouse] = useState(null);
  const [house, setHouse] = useState({
    firebaseId: "",
    title: "",
    address: "",
    price: "",
    main_image: [],
    rooms: "",
    bedrooms: "",
    sqm: "",
    floor: "",
    description: "",
    latitude: "",
    longitude: "",
  });

  const startEditing = (house) => {
    setEditingHouse(house.firebaseId);
    setHouse({
      ...house,
      main_image: house.main_image || [],
      latitude: house.latitude || "",
      longitude: house.longitude || "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'main_image') {
      setHouse({
        ...house,
        main_image: value.split(',').map(img => img.trim()),
      });
    } else {
      setHouse({
        ...house,
        [name]: value,
      });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    
    reader.onloadend = () => {
      setHouse((prevHouse) => ({
        ...prevHouse,
        main_image: [...prevHouse.main_image, reader.result],
      }));
    };

    if (file) {
      reader.readAsDataURL(file);
    }

    // Reset the file input value to allow re-uploading the same file
    e.target.value = null;
  };

  const handleImageChange = (index, value) => {
    const newImages = [...house.main_image];
    newImages[index] = value;
    setHouse({
      ...house,
      main_image: newImages,
    });
  };

  const handleAddImage = () => {
    setHouse({
      ...house,
      main_image: [...house.main_image, ""],
    });
  };

  const handleRemoveImage = (index) => {
    const newImages = house.main_image.filter((_, i) => i !== index);
    setHouse({
      ...house,
      main_image: newImages,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (validateForm()) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to update this house?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update it!'
      }).then((result) => {
        if (result.isConfirmed) {
          updateHouse(house);
          Swal.fire({
            title: 'Success!',
            text: 'House updated successfully.',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
            willClose: () => {
              setEditingHouse(null);
            }
          });
        }
      });
    } else {
      toast.error("Failed to update house. Please fill in all required fields.");
    }
  };

  const handleDelete = (firebaseId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "This action cannot be undone!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteHouse(firebaseId);
        Swal.fire({
          title: 'Deleted!',
          text: 'House has been deleted.',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    });
  };

  const validateForm = () => {
    if (!house.title || !house.address || !house.price || house.main_image.length === 0 || !house.rooms || !house.bedrooms || !house.sqm || !house.floor || !house.description || !house.latitude || !house.longitude) {
      toast.info("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  return (
    <div className="custom-manage-houses-container">
      <h1>Manage Houses</h1>
      {houses.map((houseItem) => (
        <div key={houseItem.firebaseId} className="custom-house-item">
          {editingHouse === houseItem.firebaseId ? (
            <form onSubmit={handleUpdate} className="custom-edit-form">
              <input
                type="text"
                name="title"
                value={house.title}
                onChange={handleChange}
                required
                placeholder="Title"
              />
              <input
                type="text"
                name="address"
                value={house.address}
                onChange={handleChange}
                required
                placeholder="Address"
              />
              <input
                type="text"
                name="price"
                value={house.price}
                onChange={handleChange}
                required
                placeholder="Price"
              />
              <div className="custom-form-group">
                <label>Main Images</label>
                {house.main_image.map((image, index) => (
                  <div key={index} className="custom-image-input">
                    <input
                      type="text"
                      value={image}
                      onChange={(e) => handleImageChange(index, e.target.value)}
                      placeholder={`Image URL ${index + 1}`}
                    />
                    <button type="button" onClick={() => handleRemoveImage(index)} className="remove-image-btn">Remove</button>
                  </div>
                ))}
               <input
                  id={`file-upload-${house.firebaseId}`}
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="custom-image-upload"
                />
                <label htmlFor={`file-upload-${house.firebaseId}`} className="custom-upload-label1">
                  Choose File
                </label>
                <button type="button" onClick={handleAddImage} className="custom-add-image-btn">+ Add Image</button>
              </div>
              <input
                type="text"
                name="rooms"
                value={house.rooms}
                onChange={handleChange}
                required
                placeholder="Rooms"
              />
              <input
                type="text"
                name="bedrooms"
                value={house.bedrooms}
                onChange={handleChange}
                required
                placeholder="Bedrooms"
              />
              <input
                type="text"
                name="sqm"
                value={house.sqm}
                onChange={handleChange}
                required
                placeholder="Square Meters"
              />
              <input
                type="text"
                name="floor"
                value={house.floor}
                onChange={handleChange}
                required
                placeholder="Floor"
              />
              <textarea
                name="description"
                value={house.description}
                onChange={handleChange}
                required
                placeholder="Description"
              />
              <input
                type="text"
                name="latitude"
                value={house.latitude}
                onChange={handleChange}
                required
                placeholder="Latitude"
              />
              <input
                type="text"
                name="longitude"
                value={house.longitude}
                onChange={handleChange}
                required
                placeholder="Longitude"
              />
              <div className="custom-button-group">
                <button type="submit">Update</button>
                <button type="button" onClick={() => setEditingHouse(null)}>Cancel</button>
              </div>
            </form>
          ) : (
            <div className="custom-house-details">
              <h2>{houseItem.title}</h2>
              <p><strong>Address:</strong> {houseItem.address}</p>
              <p><strong>Price:</strong> {houseItem.price}</p>
              <p><strong>Rooms:</strong> {houseItem.rooms}</p>
              <p><strong>Bedrooms:</strong> {houseItem.bedrooms}</p>
              <p><strong>Square Meters:</strong> {houseItem.sqm}</p>
              <p><strong>Floor:</strong> {houseItem.floor}</p>
              <p><strong>Latitude:</strong> {houseItem.latitude}</p>
              <p><strong>Longitude:</strong> {houseItem.longitude}</p>
              <div className="custom-manage-buttons">
                <button onClick={() => startEditing(houseItem)} className="edit-btn">Edit</button>
                <button onClick={() => handleDelete(houseItem.firebaseId)} className="delete-btn">Delete</button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ManageHouses;
