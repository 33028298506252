import React, { useState, useEffect, useCallback } from 'react';
import '../style/LatestProperties.css';
import { FaBed, FaBath, FaRulerCombined, FaLayerGroup, FaMapMarkerAlt, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import { scroller } from 'react-scroll';

const LatestProperties = ({ houses = [], onMapFocus }) => {
    const [visibleHouses, setVisibleHouses] = useState([]);
    const [imageIndexes, setImageIndexes] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const initialVisible = window.innerWidth < 768 ? 2 : 6;
        setVisibleHouses(houses.slice(0, initialVisible));
    }, [houses]);

    const handleLoadMore = useCallback(() => {
        const increment = window.innerWidth < 768 ? 2 : 6;
        setVisibleHouses(prevVisibleHouses => [
            ...prevVisibleHouses,
            ...houses.slice(prevVisibleHouses.length, prevVisibleHouses.length + increment),
        ]);
    }, [houses]);

    const handleNextImage = useCallback((e, propertyId) => {
        e.stopPropagation();
        setImageIndexes(prevIndexes => {
            const currentIndex = prevIndexes[propertyId] || 0;
            const nextIndex = (currentIndex + 1) % houses.find(h => h.id === propertyId).main_image.length;
            return { ...prevIndexes, [propertyId]: nextIndex };
        });
    }, [houses]);

    const handlePreviousImage = useCallback((e, propertyId) => {
        e.stopPropagation();
        setImageIndexes(prevIndexes => {
            const currentIndex = prevIndexes[propertyId] || 0;
            const property = houses.find(h => h.id === propertyId);
            const previousIndex = currentIndex === 0 ? property.main_image.length - 1 : currentIndex - 1;
            return { ...prevIndexes, [propertyId]: previousIndex };
        });
    }, [houses]);

    const handleMapIconClick = useCallback((e, property) => {
        e.stopPropagation();
        if (property.latitude && property.longitude) {
            onMapFocus(parseFloat(property.latitude), parseFloat(property.longitude));
            setTimeout(() => {
                scroller.scrollTo('map', {
                    duration: 800,
                    delay: 0,
                    smooth: 'easeInOutQuart',
                    offset: -150 
                });
            }, 300);
        } else {
            console.error("Invalid latitude or longitude:", property.latitude, property.longitude);
        }
    }, [onMapFocus]);

    const handleCardClick = useCallback((id) => {
        navigate(`/house/${id}`);
    }, [navigate]);

    if (!houses || houses.length === 0) {
        return <p>No properties available.</p>;
    }

    return (
        <section className="custom-latest-properties">
            <h2>Our Latest</h2>
            <div className="custom-properties-grid">
                {visibleHouses.map(property => (
                    <div className="custom-property-card" key={property.id} onClick={() => handleCardClick(property.firebaseId)}>
                        <div className="custom-property-image-wrapper">
                            {property.main_image && property.main_image.length > 0 ? (
                                <div className="custom-carousel">
                                    <button className="custom-carousel-button left" onClick={(e) => handlePreviousImage(e, property.id)}>
                                        <FaChevronLeft />
                                    </button>
                                    <img
                                        src={property.main_image[imageIndexes[property.id] || 0]}
                                        alt={property.title}
                                        className="custom-property-image"
                                        onError={(e) => {
                                            e.target.src = 'path/to/default-image.jpg';
                                        }}
                                    />
                                    <div className="custom-property-image-overlay"></div>
                                    <div className="custom-property-price-overlay">
                                        <p className="custom-property-price">{property.price}</p>
                                    </div>
                                    <button className="custom-carousel-button right" onClick={(e) => handleNextImage(e, property.id)}>
                                        <FaChevronRight />
                                    </button>
                                </div>
                            ) : (
                                <img
                                    src="path/to/default-image.jpg"
                                    alt="Default"
                                    className="custom-property-image"
                                />
                            )}
                        </div>
                        <div className="custom-property-details">
                            <div className="custom-property-header">
                                <h3 className="custom-property-title">{property.title}</h3>
                                <div className="custom-map-link" onClick={(e) => e.stopPropagation()}>
                                    <span className="custom-map-text">Find on map</span>
                                    <Link 
                                        to="map" 
                                        smooth={true} 
                                        duration={1000}
                                        onClick={(e) => handleMapIconClick(e, property)}
                                    >
                                        <FaMapMarkerAlt
                                            className="custom-map-icon"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <p className="custom-property-address">{property.address}</p>
                            <br/>
                            <div className="custom-property-info">
                                <p>
                                    <span><FaBath /> {property.rooms}</span> 
                                    <label>Rooms</label>
                                </p>
                                <p>
                                    <span><FaBed /> {property.bedrooms}</span>
                                    <label>Bedrooms</label>
                                </p>
                                <p>
                                    <span><FaRulerCombined /> {property.sqm}</span>
                                    <label>Sqm</label>
                                </p>
                                <p>
                                    <span><FaLayerGroup /> {property.floor}</span>
                                    <label>Floor</label>
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {visibleHouses.length < houses.length && (
                <button className="custom-load-more-btn" onClick={handleLoadMore}>
                    Load More
                </button>
            )}
        </section>
    );
};

export default LatestProperties;
