import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Office from './components/Office';
import LatestProperties from './components/LatestProperties';
import Contact from './components/Contact';
import AdminPanel from './components/management/AdminPanel';
import AdminModal from './components/management/AdminModal';
import Testimonials from './components/Testimonials';
import Services from './components/Services';
import Map from './components/Map';
import HouseDetail from './components/HouseDetail';
import MapLoader from './components/MapLoader'; // כאן נטען את המפות
import './style/App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { db } from './firebase';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs } from 'firebase/firestore';

function App() {
    const [houses, setHouses] = useState([]); // רשימת הבתים
    const [focusedLocation, setFocusedLocation] = useState(null);
    const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
    const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);

    const handleMapFocus = (latitude, longitude) => {
        setFocusedLocation({ latitude, longitude });
    };

    useEffect(() => {
        const fetchHouses = async () => {
            const housesCollection = collection(db, 'houses');
            const housesSnapshot = await getDocs(housesCollection);
            const housesList = housesSnapshot.docs.map(doc => ({ firebaseId: doc.id, ...doc.data() }));
            setHouses(housesList);
        };

        fetchHouses();
    }, []);

    useEffect(() => {
        const isAdmin = localStorage.getItem('isAdminAuthenticated');
        if (isAdmin === 'true') {
            setIsAdminAuthenticated(true);
        }
    }, []);

    const addHouse = async (house) => {
        try {
            const housesCollection = collection(db, 'houses');
            const docRef = await addDoc(housesCollection, house);
            setHouses([...houses, { firebaseId: docRef.id, ...house }]);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const updateHouse = async (updatedHouse) => {
        try {
            const houseDocRef = doc(db, 'houses', updatedHouse.firebaseId);
            await updateDoc(houseDocRef, updatedHouse);
            setHouses(houses.map(h => h.firebaseId === updatedHouse.firebaseId ? updatedHouse : h));
        } catch (error) {
            console.error("Error updating document: ", error);
        }
    };

    const deleteHouse = async (firebaseId) => {
        try {
            const houseDocRef = doc(db, 'houses', firebaseId);
            await deleteDoc(houseDocRef);
            setHouses(houses.filter(h => h.firebaseId !== firebaseId));
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    };

    const handleAdminLogin = () => {
        setIsAdminModalOpen(true);
    };

    const handleAdminClose = () => {
        setIsAdminModalOpen(false);
    };

    const handleAdminAuth = () => {
        setIsAdminAuthenticated(true);
        localStorage.setItem('isAdminAuthenticated', 'true');
        setIsAdminModalOpen(false);
    };

    return (
        <Router>
            <Navbar onAdminClick={handleAdminLogin} isAdminAuthenticated={isAdminAuthenticated} />
            <Routes>
                <Route path="/" element={
                    <>
                        <section id="home">
                            <Home />
                        </section>
                        <section id="about-us">
                            <Office />
                        </section>
                        <section id="properties">
                            <LatestProperties houses={houses} onMapFocus={handleMapFocus} />
                        </section>
                        <section id="testimonials">
                            <Testimonials />
                        </section>
                        <section id="services">
                            <Services />
                        </section>
                        <section id="map">
                            <MapLoader>
                                <Map houses={houses} focusedLocation={focusedLocation} />
                            </MapLoader>
                        </section>
                        <section id="contact">
                            <Contact />
                        </section>
                    </>
                } />
                <Route path="/house/:id" element={
                    <MapLoader>
                        <HouseDetail houses={houses} />
                    </MapLoader>
                } />
                <Route path="/admin" element={
                    isAdminAuthenticated ? 
                    <AdminPanel
                        houses={houses}
                        addHouse={addHouse}
                        updateHouse={updateHouse}
                        deleteHouse={deleteHouse}
                    /> : 
                    <Navigate to="/" />
                } />
            </Routes>
            {isAdminModalOpen && (
                <AdminModal onClose={handleAdminClose} onLogin={handleAdminAuth} />
            )}
        </Router>
    );
}

export default App;
