import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, InfoWindow, Circle } from '@react-google-maps/api';
import '../style/map.css';
import { useNavigate } from 'react-router-dom';

const Map = ({ houses = [], focusedLocation }) => {
    const [selectedHouse, setSelectedHouse] = useState(null);
    const [hoveredMarker, setHoveredMarker] = useState(null);
    const [zoomLevel, setZoomLevel] = useState(14);
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null); // Reference for the map container
    const navigate = useNavigate();

    const handleInfoWindowClick = (id) => {
        navigate(`/house/${id}`);
    };

    const handleToggleFullscreen = () => {
        if (!document.fullscreenElement) {
            mapContainerRef.current.requestFullscreen().then(() => {
                mapContainerRef.current.classList.add('fullscreen'); // Add the fullscreen class
            }).catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen().then(() => {
                mapContainerRef.current.classList.remove('fullscreen'); // Remove the fullscreen class
            });
        }
    };

    useEffect(() => {
        if (focusedLocation && mapRef.current) {
            const { latitude, longitude } = focusedLocation;
            if (latitude && longitude && !isNaN(latitude) && !isNaN(longitude)) {
                const focusedHouse = houses.find(h => parseFloat(h.latitude) === parseFloat(latitude) && parseFloat(h.longitude) === parseFloat(longitude));
                if (focusedHouse) {
                    mapRef.current.panTo({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
                    mapRef.current.setZoom(18);
                    setSelectedHouse(focusedHouse);
                }
            } else {
                console.error("Invalid focusedLocation coordinates:", latitude, longitude);
            }
        }
    }, [focusedLocation, houses]);

    const mapOptions = {
        disableDefaultUI: true,
        zoomControl: true,
        draggable: true,
        scrollwheel: true,
        disableDoubleClickZoom: true,
        minZoom: 10,
        maxZoom: 20,
        clickableIcons: false,
        styles: [
            {
                featureType: "poi",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }]
            },
            {
                featureType: "road",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }]
            }
        ]
    };

    const calculateDistance = (lat1, lng1, lat2, lng2) => {
        const R = 6371;
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLng = (lng2 - lng1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
    };

    const getDetectionDistance = (zoom) => {
        if (zoom >= 18) return 0.05;
        if (zoom >= 16) return 0.1;
        if (zoom >= 14) return 0.2;
        return 0.5;
    };

    const handleMapMouseMove = (e) => {
        if (selectedHouse) return;

        const { latLng } = e;
        const mouseLat = latLng.lat();
        const mouseLng = latLng.lng();
        const detectionDistance = getDetectionDistance(zoomLevel);

        let foundNearbyMarker = false;

        houses.forEach(house => {
            const distance = calculateDistance(
                mouseLat,
                mouseLng,
                parseFloat(house.latitude),
                parseFloat(house.longitude)
            );

            if (distance <= detectionDistance) {
                setHoveredMarker(house);
                foundNearbyMarker = true;
            }
        });

        if (!foundNearbyMarker) {
            setHoveredMarker(null);
        }
    };

    const handleMarkerClick = (house) => {
        if (mapRef.current) {
            mapRef.current.panTo({ lat: parseFloat(house.latitude), lng: parseFloat(house.longitude) });
            mapRef.current.setZoom(18);
        }
        setSelectedHouse(house);
        setHoveredMarker(null);
    };

    return (
        <div className='map-container' ref={mapContainerRef}>
            <button onClick={handleToggleFullscreen} className="fullscreen-toggle-btn">
                Toggle Fullscreen
            </button>
            <GoogleMap
                zoom={zoomLevel}
                center={focusedLocation && focusedLocation.latitude && focusedLocation.longitude ? 
                    { lat: parseFloat(focusedLocation.latitude), lng: parseFloat(focusedLocation.longitude) } : 
                    { lat: 31.7683, lng: 35.2137 }}
                mapContainerStyle={{ height: '100%', width: '100%' }} // Adjust height and width to take full available space
                options={mapOptions}
                onMouseMove={handleMapMouseMove}
                onLoad={(map) => (mapRef.current = map)}
                onZoomChanged={() => {
                    if (mapRef.current) {
                        setZoomLevel(mapRef.current.getZoom());
                    }
                }}
            >
                {houses && houses.length > 0 && houses.map(house => (
                    <React.Fragment key={house.firebaseId}>
                        <Marker
                            position={{ lat: parseFloat(house.latitude), lng: parseFloat(house.longitude) }}
                            onClick={() => handleMarkerClick(house)}
                            icon={{
                                url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRBjKuvazb1KfHOYqWst_xScm4wegP8alqFA&s",
                                scaledSize: new window.google.maps.Size(30, 30),
                            }}
                        />
                        <Circle
                            center={{ lat: parseFloat(house.latitude), lng: parseFloat(house.longitude) }}
                            radius={20}
                            options={{
                                fillColor: 'transparent',
                                strokeColor: 'transparent',
                                clickable: true,
                            }}
                            onClick={() => handleMarkerClick(house)}
                        />
                    </React.Fragment>
                ))}

                {(hoveredMarker || selectedHouse) && (
                    <InfoWindow
                        position={{ lat: parseFloat((hoveredMarker || selectedHouse).latitude), lng: parseFloat((hoveredMarker || selectedHouse).longitude) }}
                        onCloseClick={() => {
                            setSelectedHouse(null);
                            setHoveredMarker(null);
                        }}
                    >
                        <div className="info-window-content">
                            <img src={(hoveredMarker || selectedHouse).main_image[0]} alt={(hoveredMarker || selectedHouse).title} className="info-window-image" />
                            <h2 className="info-window-title">{(hoveredMarker || selectedHouse).title}</h2>
                            <p className="info-window-price">{(hoveredMarker || selectedHouse).price}</p>
                            <button
                                className='btn1'
                                onClick={() => handleInfoWindowClick((hoveredMarker || selectedHouse).firebaseId)}
                            >
                                Enter the house
                            </button>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        </div>
    );
};

export default Map;
