import React from 'react';
import AddHouse from './AddHouse';
import ManageHouses from './ManageHouses';

function AdminPanel({ houses, addHouse, updateHouse, deleteHouse }) {
    return (
        <div className="admin-panel">
            <AddHouse addHouse={addHouse} />
            <ManageHouses houses={houses} updateHouse={updateHouse} deleteHouse={deleteHouse} />
        </div>
    );
}

export default AdminPanel;
