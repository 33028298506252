import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC681zFOBOWH-bHJVhH_mW3tf30d-ni9GQ",
    authDomain: "real-estate-site-9f38f.firebaseapp.com",
    projectId: "real-estate-site-9f38f",
    storageBucket: "real-estate-site-9f38f.appspot.com",
    messagingSenderId: "633008507622",
    appId: "1:633008507622:web:d824e7033df19feed44927",
    measurementId: "G-0366YBG0WX"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
