import React, { useState } from 'react';
import '../style/Testimonials.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const testimonialsData = [
    {
        id: 1,
        name: 'John Doe',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVEjoPV0LSFFVpaHXtFwdDGOczYXCIwZmi-Q&s',
        comment: 'MY provided excellent service from start to finish. The agents were professional, knowledgeable, and truly cared about finding the perfect home for my family. We highly recommend their services to anyone in the market for a new home. Thank you, JHomes, for making our dream home a reality!'
    },
    {
        id: 2,
        name: 'Jane Smith',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-9_j8HYfmDxMM7m78MuuZ5ZEnbCG_ePPBXQ&s',
        comment: 'I had an outstanding experience with MY. The process was seamless, and the team went above and beyond to ensure that all of my needs were met. From the initial consultation to the final closing, they were with me every step of the way. I am incredibly grateful for their expertise and dedication.'
    },
    {
        id: 3,
        name: 'Emily Johnson',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQUUzpyi3Hl2UdDKtdxchbn-UF-Y1BKBOd7hA&s',
        comment: 'Working with MY was the best decision I made during my home-buying process. They listened to what I was looking for and helped me find a home that exceeded all of my expectations. The level of service and attention to detail was second to none. I would definitely work with them again in the future.'
    }
];

function Testimonials() {
    const [currentTestimonial, setCurrentTestimonial] = useState(0);

    const nextTestimonial = () => {
        setCurrentTestimonial((prev) => (prev + 1) % testimonialsData.length);
    };

    const prevTestimonial = () => {
        setCurrentTestimonial((prev) => (prev - 1 + testimonialsData.length) % testimonialsData.length);
    };

    return (
        <div className="testimonials-section">
            <h2>What people say about us</h2>
            <div className="testimonial-container">
                <button className="testimonial-arrow left" onClick={prevTestimonial}>
                    <FaChevronLeft />
                </button>
                <div className="testimonial">
                    <img src={testimonialsData[currentTestimonial].image} alt={testimonialsData[currentTestimonial].name} className="testimonial-image" />
                    <p className="testimonial-comment">"{testimonialsData[currentTestimonial].comment}"</p>
                    <p className="testimonial-name">- {testimonialsData[currentTestimonial].name}</p>
                </div>
                <button className="testimonial-arrow right" onClick={nextTestimonial}>
                    <FaChevronRight />
                </button>
            </div>
        </div>
    );
}

export default Testimonials;
